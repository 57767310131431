import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #00365f;
`;

export const ContainerCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 50px;
  padding: 20px;
  max-width: 1200px;
  margin-bottom: 80px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 153px;
  max-width: 153px;
  padding: 20px;
  border-radius: 7px;
  background-color: white;
  text-align: center;
  cursor: pointer;
  &:hover {
    border: none;
    box-shadow: 0px 0px 16px 0px white;
    transition: all 0.3s ease;
  }
  @media (max-width: 768px) {
    width: 150px;
    max-width: 150px;
    height: 130px;
    max-height: 130px;
  }
`;

export const Image = styled.img`
  height: 75px;
`;

export const Title = styled.p`
  color: #00365f;
  font-size: 18px;
  font-weight: bold;
`;
