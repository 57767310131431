import React from "react";
import { formasdepagamento } from "../../data";
import IntroducaoText from "../IntroducaoText";
import * as S from "./styles";

export default function SecaoFormasDePagamento() {
  return (
    <S.Container>
      <IntroducaoText
        title="Formas de pagamento"
        subtitle="Escolha a ideal para você !"
        subtitleColor="white"
      />
      <S.ContainerCard>
        {formasdepagamento.map((item, index) => (
          <S.Card key={index}>
            <S.Image src={item.image} alt={item.title} />
            <S.Title>{item.title}</S.Title>
          </S.Card>
        ))}
      </S.ContainerCard>
    </S.Container>
  );
}
