import styled from "styled-components";

export const Container = styled.div`
  display: flex;
`;

export const Button = styled.button`
  padding: 20px 30px 20px 30px;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  background-color: #00365f;
  color: white;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background-color: #9ac31c;
    font-weight: bold;
  }
`;
