import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerAlignCards = styled.div`
  display: flex;
  align-items: stretch;
  margin: 20px;
  gap: 20px;
  max-width: 900px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ContainerImageBorder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  padding: 42px;
  position: relative;
  flex-direction: column;
  order: ${(props) => (props.index % 2 === 0 ? 1 : 2)};
  border: 1px solid #eeeeee;
  border-radius: 16px;
  @media (max-width: 768px) {
    order: 1;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Button = styled.button`
  position: absolute;
  font-family: "Raleway", sans-serif;
  bottom: 10px;
  padding: 12px;
  background-color: white;
  border: 1px solid #eeeeee;
  border-radius: 7px;
  color: #00365f;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 5px;
  &:hover {
    background-color: #9ac31c;
    color: white;
    box-shadow: 0px 8px 16px 0px #00365f;
    transition: all 0.3s ease;
    border: none;
  }

  @media (max-width: 768px) {
    bottom: 9px;
    padding: 10px;
    font-size: 14px;
  }
`;

export const Image = styled.img`
  margin-bottom: 30px;
  object-fit: cover;
`;

export const ContainerTexts = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  border-radius: 16px;
  order: ${(props) => (props.index % 2 === 0 ? 2 : 1)};
  background-color: #00365f;
  padding: 12px;
  &:hover {
    box-shadow: 0px 0px 16px 0px #00365f;
    transition: all 0.3s ease;
  }
  @media (max-width: 768px) {
    height: auto;
    width: 100%;
  }
`;

export const Title = styled.p`
  font-size: 23px;
  color: white;
  font-weight: bolder;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const SubTitle = styled.p`
  font-size: 22px;
  color: white;
  font-weight: 300;
  margin: 0;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const Text = styled.p`
  font-size: 18px;
  color: white;

  @media (max-width: 768px) {
    text-align: center;
  }
`;
