import React, { useEffect } from "react";
import NavBar from "../../components/NavBar";
import Section from "../../components/Section";
import IntroducaoChatbot from "../../components/IntroducaoChatbot";
import IntroducaoFuncionalidades from "../Funcionalidades/components/IntroducaoFuncionalidades";
import BannerProximoPasso from "../../components/BannerProximoPasso";
import Carrousel from "../../components/Carrousel";
import BannerDesbloqueie from "../../components/BannerDesbloqueie";
import SecaoFormasDePagamento from "../../components/SecaoFormasDePagamento";
import Formulario from "../Contato/components/Formulario";
import ButtonFlutuante from "../../components/ButtonFlutuante";
import Footer from "../../components/Footer";
import * as S from "./styles";

const Home = (props) => {
  useEffect(() => {
    if (props?.state) {
      const element = document.getElementById("contato");
      window.scrollTo(0, element.offsetTop);
    }
  }, [props?.state]);

  return (
    <S.Container>
      <>
        <NavBar isTabs />
        <Section />
        <IntroducaoChatbot />
        <IntroducaoFuncionalidades />
        <BannerProximoPasso />
        <Carrousel />
        <SecaoFormasDePagamento />
        <BannerDesbloqueie />
        <Formulario />
        <ButtonFlutuante />
        <Footer />
      </>
    </S.Container>
  );
};

export default Home;
