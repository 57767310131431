import React, { useEffect, useState } from "react";
import { chatinterno, chatinternoDetails } from "../../data";
import chatInternoImg from "../../imgs/imgsMockupBanner/chatinterno.svg";
import NavBar from "../../components/NavBar";
import TextoIntroducaoFuncionalidade from "../../components/TextoIntroducaoFuncionalidade";
import CardFuncionalidade from "../../components/CardFuncionalidade";
import ButtonContact from "../../components/ButtonContact";
import Footer from "../../components/Footer";
import ButtonFlutuante from "../../components/ButtonFlutuante";
import * as S from "./styles";

export default function ChatInterno() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);
  window.scrollTo(0, 0);
  return (
    <>
      {isLoading && (
        <S.LoadingContainer>
          <S.LoadingSpinner />
        </S.LoadingContainer>
      )}
      {!isLoading && (
        <S.Container>
          {chatinternoDetails.map((item, index) => (
            <S.ContainerPosition key={index}>
              <NavBar isTabs={false} />
              <TextoIntroducaoFuncionalidade title={item.title} />
              <S.ContainerDuplo>
                <S.ImageMockup src={item.image} />
                <S.ContainerTextosColumn>
                  <S.TitleInformation>{item.texto1}</S.TitleInformation>
                  <S.subTitle>{item.texto2}</S.subTitle>
                </S.ContainerTextosColumn>
              </S.ContainerDuplo>
              <S.Title>
                Descubra os benefícios exclusivos do chat interno !
              </S.Title>
            </S.ContainerPosition>
          ))}
          {chatinterno.map((item, index) => (
            <CardFuncionalidade
              key={index}
              card={item}
              index={index}
              widthImage="290px"
              containerHeigth="300px"
              containerHeigthText="290px"
            />
          ))}
          <S.BackgroundImage>
            <S.ContainerDuplo>
              <S.Image src={chatInternoImg} />
              <S.ContainerTextosColumn>
                <S.TitleInformation>CHAT INTERNO</S.TitleInformation>
                <S.TitleInformationSecundario>
                  INTELIGENTE
                </S.TitleInformationSecundario>
                <S.subTitleInformation>ÁGIL </S.subTitleInformation>
                <S.subTitleInformation>DINÂMICO</S.subTitleInformation>
                <S.subTitleInformation>CONFIÁVEL</S.subTitleInformation>
                <S.ContainerButton>
                  <ButtonContact
                    title="Comece agora"
                    url="https://wa.me/+554834782300"
                  />
                </S.ContainerButton>
              </S.ContainerTextosColumn>
            </S.ContainerDuplo>
          </S.BackgroundImage>
          <Footer />
          <ButtonFlutuante />
        </S.Container>
      )}
    </>
  );
}
