import React from "react";
import logoVox from "../../imgs/logoVox/logovoxsmall.svg";
import * as S from "./styles";

export default function TextoIntroducaoFuncionalidade({ title }) {
  return (
    <S.ContainerSection>
      <S.Image src={logoVox} />
      <S.Title>{title}</S.Title>
    </S.ContainerSection>
  );
}
