import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #00365f;
  width: 100%;
`;

export const ContainerSpaceContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-left: 50px;
  padding: 50px;
  @media (max-width: 1180px) {
    justify-content: space-between;
    margin-left: 0px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
    margin-left: 0px;
    padding: 0px;
    text-align: center;
  }
`;

export const ContainerAlign = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-around;
  padding: 0px;
  flex-direction: row;
  margin: 30px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const ContainerSpaceAlign = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  @media (max-width: 768px) {
    gap: 10px;
    align-items: center;
    justify-content: center;
  }
`;

export const ContainerRedes = styled.div`
  display: flex;
  gap: 10px;
  margin: 15px 0px;
  align-items: center;
`;

export const ContainerButtonRede = styled.div`
  display: flex;
`;

export const Space = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
  }
`;

export const Align = styled.div`
  @media (max-width: 768px) {
    margin-top: 25px;
  }
`;

export const ContainerMargin = styled.div`
  margin-top: ${(props) => props.margintop || "0px"};
  @media (max-width: 768px) {
    margin-top: 18px;
  }
`;

export const ContainerCenter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
  @media (max-width: 768px) {
    gap: 25px;
    align-items: center;
    justify-content: center;
  }
`;

export const Title = styled.p`
  font-weight: bold;
  font-size: 20px;
  color: #9ac31c;
  margin-top: 0px;
`;

export const Text = styled.p`
  color: white;
  cursor: pointer;
  font-size: 18px;
  font-weight: bolder;
  margin: 10px 0px 28px 0px;
  text-decoration: none;
  &:hover {
    color: #9ac31c;
    font-weight: bold;
  }
`;

export const TextNumber = styled.p`
  color: white;
  cursor: pointer;
  font-size: 18px;
  font-weight: bolder;
  text-decoration: none;
  &:hover {
    color: #9ac31c;
    font-weight: bold;
  }
`;

export const AlignTags = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (max-width: 768px) {
    align-items: center;
  }
`;

export const TextNormal = styled.p`
  color: white;
  font-size: 18px;
  margin: 3px;
`;

export const TextContato = styled.p`
  color: white;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 0px;
  font-weight: 500;
`;

export const TextEmail = styled.p`
  color: white;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 0px;
  font-weight: bolder !important;
`;

export const Image = styled.img`
  max-width: 200px;
`;

export const IconRede = styled.img`
  width: 20px;
  height: 20px;
`;

export const ButtonContainer = styled.div`
  margin-top: 10px;
`;

export const ButtonRede = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #9ac31c;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  &:hover {
    border: 2px solid #9ac31c;
    box-shadow: 0px 8px 16px 0px white;
    transition: all 0.3s ease;
  }
`;
