import styled, { keyframes } from "styled-components";
import fundovox from "../../imgs/backgrounds/fundoSecundario.png";

export const ani = keyframes`
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px); 
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const spin = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

export const LoadingSpinner = styled.div`
  border: 6px solid rgba(0, 0, 0, 0.1);
  border-top: 6px solid #00365f;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Title = styled.p`
  font-size: 30px;
  font-weight: bold;
  color: #00365f;
  margin: 0px 0px 50px 0px;
  @media (max-width: 768px) {
    text-align: center;
    font-size: 18px;
    margin: 20px 0px 30px 0px;
  }
`;

export const TitleInformation = styled.p`
  font-size: 25px;
  font-weight: bold;
  color: #00365f;
  margin-bottom: 5px;
  @media (max-width: 1000px) {
    text-align: center;
  }
  @media (max-width: 768px) {
    text-align: center;
    font-size: 18px;
    margin-bottom: 0px;
  }
`;

export const subTitle = styled.p`
  font-size: 22px;
  font-weight: 500;
  color: #00365f;
  @media (max-width: 1000px) {
    text-align: center;
  }
  @media (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 0px;
  }
`;

export const TitleInformationSecundario = styled.p`
  font-size: 25px;
  color: #00365f;
  margin-top: 0px;
  font-weight: 900;
  @media (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 0px;
  }
`;

export const subTitleInformation = styled.p`
  font-size: 22px;
  font-weight: 500;
  color: #00365f;
  margin-top: 0px;
  &::before {
    content: "";
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: #00365f;
    border-radius: 50%;
    margin-right: 8px;
    vertical-align: middle;
  }
  @media (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 0px;
  }
`;

export const BackgroundImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  overflow: hidden;
  background-image: url(${fundovox});
  background-size: cover;
  margin-top: 60px;
`;

export const ContainerPosition = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ContainerDuplo = styled.div`
  display: flex;
  width: 70%;
  align-items: center;
  flex-direction: row;
  gap: 50px;
  @media (max-width: 1000px) {
    width: 85%;
    flex-direction: column;
    margin-bottom: 40px;
    gap: 0px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 0px;
    margin: 20px 0px 30px 0px;
  }
`;

export const ImageMockup = styled.img`
  animation: ${ani} 2s ease-in-out infinite alternate-reverse both;
  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const Image = styled.img`
  width: 500px;
  @media (max-width: 768px) {
    width: 80%;
  }
`;

export const ContainerTextosColumn = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 90%;
    gap: 20px;
    align-items: center;
    margin-bottom: 20px;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  margin-top: 10px;
`;
