import {
  BsFillPersonFill,
  BsFillTelephoneFill,
  BsFillEnvelopeFill,
  BsChatSquareTextFill,
} from "react-icons/bs";
import styled, { keyframes } from "styled-components";

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const swing = keyframes`
  20% {
    transform: rotate(15deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  60% {
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
`;

export const ContainerForm = styled.form`
  display: flex;
  margin-top: 30px;
  align-items: center;
  border: 1px solid #ddd;
  flex-direction: column;
  width: 50%;
  max-width: 700px;
  border-radius: 34px;
  padding: 80px;
  gap: 8px;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    width: 70%;
    padding: 30px;
  }
`;

export const InputComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Label = styled.p`
  font-size: 18px;
  font-weight: bold;
  margin-left: 12px;
  color: #00365f;
  &:after {
    content: "*";
    display: inline;
    color: red;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  border: 1px solid #00365f;
  padding-left: 8px;
  width: 100%;
  &:hover,
  &:focus-within {
    border: 1px solid #9ac31c;
  }
`;

export const InputIcon = styled(BsFillPersonFill)`
  padding: 9px 0;
  width: 20px;
  max-width: 20px;
  height: 20px;
  max-height: 20px;
  @media (max-width: 768px) {
    padding: 10px 0;
    width: 30px;
  }
`;

export const InputIconPhone = styled(BsFillTelephoneFill)`
  padding: 9px 0;
  width: 20px;
  max-width: 20px;
  height: 20px;
  max-height: 20px;
`;

export const InputIconEmail = styled(BsFillEnvelopeFill)`
  padding: 9px 0;
  width: 20px;
  max-width: 20px;
  height: 20px;
  max-height: 20px;
`;

export const InputIconMessage = styled(BsChatSquareTextFill)`
  padding: 9px 0;
  width: 20px;
  max-width: 20px;
  height: 20px;
  max-height: 20px;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Input = styled.input`
  border: none;
  margin: 2px;
  width: 100%;
  outline: none;
  font-size: 14px;
  font-family: "Raleway", sans-serif;
  &::placeholder {
    color: #b8b8b8;
    font-weight: 500;
  }
`;

export const InputTextArea = styled.textarea`
  border: none;
  margin: 5px;
  width: 100%;
  outline: none;
  min-height: 110px;
  font-size: 14px;
  font-family: "Raleway", sans-serif;
  &::placeholder {
    color: #b8b8b8;
    font-weight: 500;
  }
`;

export const DoubleInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 16px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  width: 100%;
  margin-top: 24px;
  justify-content: flex-end;
  margin-left: 18px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${fadeIn} 0.5s ease-in-out;
`;

export const ModalContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  border: none;
  border-radius: 10px;
  padding: 40px;
  animation: ${swing} 1s ease-in-out;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  max-width: 500px;
  @media (max-width: 768px) {
    margin: 10px;
    max-width: 300px;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: #fff;
  color: #333;
  border: none;
  font-size: 16px;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 5px;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
  &:hover {
    background: #9ac31c;
    color: #fff;
  }
`;

export const TitleTextModal = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: #00365f;
`;

export const imageLogo = styled.img`
  width: 100%;
  max-width: 250px;
`;
