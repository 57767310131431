import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  @media (max-width: 768px) {
    margin-bottom: 0px;
  }
`;

export const ContainerCarousel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CarouselContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  margin-top: 50px;
  margin-bottom: 50px;
  @media (max-width: 1180px) {
    gap: 42px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 10px;
    gap: 30px;
  }
`;

export const CarouselItem = styled.img`
  height: 110px;
  width: 90px;
  max-width: 90px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  &:hover {
    transform: scale(1.5);
    rotate: 10deg;
    transition-delay: 0.5s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    opacity: 0.8;
  }
  @media (max-width: 768px) {
    height: 110px;
    width: 110px;
    &:hover {
      rotate: 0deg;
    }
  }
`;

export const CarouselButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: white;
  border: 1px solid #9ac31c;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: #9ac31c;
  }
`;
