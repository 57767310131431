// styles.js

import styled, { css, keyframes } from "styled-components";

export const jump = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  
  40% {
    transform: translateY(-10px);
  }
  
  60% {
    transform: translateY(-5px);
  }
`;

export const StyledButton = styled.button`
  display: ${({ isvisible }) => (isvisible ? "block" : "none")};
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #9ac31c;
  color: #fff;
  font-size: 18px;
  padding: 23px;
  cursor: pointer;
  border-radius: 50%;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  box-shadow: 0px 0px 8px 0px #9ac31c, 0px 2px 12px #00365f;
  animation: ${({ isvisible }) =>
    isvisible
      ? css`
          ${jump} 1s infinite
        `
      : "none"};

  &:hover {
    background-color: white;
  }
`;

export const ContainerIconAlign = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
