import React from "react";
import vox from "../../imgs/logoVox/vox.svg";
import face from "../../imgs/iconsFooter/face.svg";
import linkedin from "../../imgs/iconsFooter/linkedin.svg";
import instagram from "../../imgs/iconsFooter/insta.svg";
import wpp from "../../imgs/iconsFooter/wpp.svg";
import * as S from "./styles";

export default function Footer() {
  return (
    <S.Container>
      <S.ContainerSpaceContainer>
        <S.ContainerAlign>
          <S.ContainerSpaceAlign>
            <S.Image src={vox} alt="Logo VoxCity" />
            <S.ContainerMargin margintop="50px">
              <S.Title>Localização</S.Title>
            </S.ContainerMargin>
            <S.ContainerMargin margintop="20px">
              <S.TextNormal>Tv. Gabriel Benedet, 144</S.TextNormal>
              <S.TextNormal>Bairro Centro</S.TextNormal>
              <S.TextNormal>Criciúma /SC</S.TextNormal>
              <S.TextNormal>CEP 88811-055</S.TextNormal>
            </S.ContainerMargin>

            <S.ContainerMargin margintop="20px">
              <S.TextNormal>Copyright 2023 © - VoxCity</S.TextNormal>
              <S.TextNormal>Desenvolvido por VoxCity</S.TextNormal>
            </S.ContainerMargin>
          </S.ContainerSpaceAlign>

          <S.ContainerSpaceAlign>
            <S.Space>
              <S.Align>
                <S.Title>Links</S.Title>
                <S.AlignTags>
                  <S.Text
                    target="_blank"
                    rel="noopener noreferrer"
                    as="a"
                    href="/chatbot"
                  >
                    ChatBot
                  </S.Text>
                  <S.Text
                    target="_blank"
                    rel="noopener noreferrer"
                    as="a"
                    href="/chatInterno"
                  >
                    Chat Interno
                  </S.Text>

                  <S.Text
                    target="_blank"
                    rel="noopener noreferrer"
                    as="a"
                    href="/agendamento"
                  >
                    Agendamento
                  </S.Text>

                  <S.Text
                    target="_blank"
                    rel="noopener noreferrer"
                    as="a"
                    href="/videochamada"
                  >
                    Video Chamada
                  </S.Text>
                </S.AlignTags>
              </S.Align>
              <S.ContainerMargin margintop="30px">
                <S.Title>Conheça nossas soluções </S.Title>
                <S.Text
                  onClick={() =>
                    window.open("https://www.voxcity.com.br/solucoes", "_blank")
                  }
                >
                  Soluções Vox
                </S.Text>
              </S.ContainerMargin>
            </S.Space>
          </S.ContainerSpaceAlign>

          <S.ContainerSpaceAlign>
            <S.Title>Nos siga nas redes sociais </S.Title>
            <S.ContainerRedes>
              <S.ContainerButtonRede>
                <S.ButtonRede
                  onClick={() =>
                    window.open(
                      "https://www.facebook.com/voxcitytecnologia/?locale=pt_BR",
                      "_blank"
                    )
                  }
                >
                  <S.IconRede src={face} alt="Facebook" />
                </S.ButtonRede>
              </S.ContainerButtonRede>

              <S.ContainerButtonRede>
                <S.ButtonRede
                  onClick={() =>
                    window.open(
                      "https://www.linkedin.com/company/inova-telecomltda/mycompany/",
                      "_blank"
                    )
                  }
                >
                  <S.IconRede src={linkedin} alt="Linkedin" />
                </S.ButtonRede>
              </S.ContainerButtonRede>

              <S.ContainerButtonRede>
                <S.ButtonRede
                  onClick={() =>
                    window.open(
                      "https://instagram.com/voxcity_?igshid=YTQwZjQ0NmI0OA==",
                      "_blank"
                    )
                  }
                >
                  <S.IconRede src={instagram} alt="Instagram" />
                </S.ButtonRede>
              </S.ContainerButtonRede>

              <S.ContainerButtonRede>
                <S.ButtonRede
                  onClick={() =>
                    window.open("https://wa.me/+554834782300/", "_blank")
                  }
                >
                  <S.IconRede src={wpp} alt="Whatsapp" />
                </S.ButtonRede>
              </S.ContainerButtonRede>
            </S.ContainerRedes>

            <S.ContainerMargin margintop="25px">
              <S.Title>Fale conosco</S.Title>
              <S.TextContato>WhatsApp</S.TextContato>
              <S.ContainerCenter>
                <S.ButtonRede
                  onClick={() =>
                    window.open("https://wa.me/+554834782300/", "_blank")
                  }
                >
                  <S.IconRede src={wpp} alt="Whatsapp" />
                </S.ButtonRede>
                <S.TextNumber> (48) 3478-2300</S.TextNumber>
              </S.ContainerCenter>
            </S.ContainerMargin>

            <S.ContainerMargin margintop="15px">
              <S.TextNormal>E-mail</S.TextNormal>
              <S.TextEmail>atendimento@voxcity.com.br</S.TextEmail>
            </S.ContainerMargin>
          </S.ContainerSpaceAlign>
        </S.ContainerAlign>
      </S.ContainerSpaceContainer>
    </S.Container>
  );
}
