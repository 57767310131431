import sombrioSlogam from "../imgs/prefeituras/sombrio.png";
import navegantesSlogam from "../imgs/prefeituras/navegantes.png";
import gravataiSlogam from "../imgs/prefeituras/gravatai.png";
import passoDoSobradoSlogam from "../imgs/prefeituras/passodosobrado.png";
import cascavelSlogam from "../imgs/prefeituras/cascavel.png";
import fransciscoBeltraoSlogam from "../imgs/prefeituras/fransciscobeltrao.png";
import doisIrmaosSlogam from "../imgs/prefeituras/doisirmaos.png";
import viamaoSlogam from "../imgs/prefeituras/viamao.png";
import palmitalSlogam from "../imgs/prefeituras/palmital.png";
import sarandiSlogam from "../imgs/prefeituras/sarandi.png";
import icaraSlogam from "../imgs/prefeituras/icara.png";
import jaguarunaSlogam from "../imgs/prefeituras/jaguaruna.png";
import riopardoSlogam from "../imgs/prefeituras/riopardo.png";
import gasparSlogam from "../imgs/prefeituras/gaspar.png";
import biturunaSlogam from "../imgs/prefeituras/bituruna.png";

import cartaoDeCredito from "../imgs/pagamentos/img-cartao.svg";
import boleto from "../imgs/pagamentos/img-boleto.svg";
import pix from "../imgs/pagamentos/img-pix.svg";

import chatbotMockup from "../imgs/imgsMockupPhone/chatbot.jpg";
import chatinternoMockup from "../imgs/imgsMockupPhone/chatinterno.jpg";
import agendamentoMockup from "../imgs/imgsMockupPhone/agendamento.jpg";
import videochamadaMockup from "../imgs/imgsMockupPhone/videochamada.jpg";

import iconChat from "../imgs/imgscards/icons-cards/icon-chat.svg";
import iconChatInterno from "../imgs/imgscards/icons-cards/icon-chatInterno.svg";
import iconAgendamento from "../imgs/imgscards/icons-cards/icon-agendamento.svg";
import iconVideoChamada from "../imgs/imgscards/icons-cards/icon-videochamada.svg";
import imageSelo from "../imgs/imgscards/icons-cards/img-certified.svg";

import customizacao from "../imgs/chatbot/customizacao.jpg";
import gruposdeatendimento from "../imgs/chatbot/gruposdeatendimento.jpg";
import pesquisadesatisfacao from "../imgs/chatbot/pesquisadesatisfacao.jpg";
import variaveis from "../imgs/chatbot/variaveis.jpg";
import relatorios from "../imgs/chatbot/relatorios.jpg";
import atendentes from "../imgs/chatbot/atendentes.jpg";
import fluxodedialogo from "../imgs/chatbot/fluxodedialogo.jpg";
import protocolos from "../imgs/chatbot/protocolos.jpg";
import gruposdemensagem from "../imgs/chatbot/gruposdemensagem.jpg";
import mensagensgerais from "../imgs/chatbot/mensagensgerais.jpg";
import campanhasedisparos from "../imgs/chatbot/campanhasedisparos.jpg";

import colaboracaoeficiente from "../imgs/chatinterno/colaboracaoeficiente.jpg";
import compartilhamentodinamico from "../imgs/chatinterno/compartilhamentodinamico.jpg";
import comunicacaoinovadora from "../imgs/chatinterno/comunicacaoinovadora.jpg";
import reunioesdinamicas from "../imgs/chatinterno/reunioesdinamicas.jpg";
import agilidade from "../imgs/chatinterno/agilidade.jpg";
import resolucaoagil from "../imgs/chatinterno/resolucaoagil.jpg";
import ambientecolaborativo from "../imgs/chatinterno/ambientecolaborativo.jpg";
import economiadecustos from "../imgs/chatinterno/economiadecustos.jpg";
import comunicacaodiretaeficaz from "../imgs/chatinterno/colaboracaoeficiente.jpg";

import horarios from "../imgs/agendamento/horarios.jpg";
import locais from "../imgs/agendamento/locais.jpg";
import plataformaweb from "../imgs/agendamento/plataformaweb.jpg";
import vizualizacaocompleta from "../imgs/agendamento/vizualizacaocompleta.jpg";
import cadastroilimitado from "../imgs/agendamento/cadastroilimitado.jpg";
import especialidades from "../imgs/agendamento/especialidades.jpg";
import personalizavel from "../imgs/agendamento/personalizavel.jpg";
import agendamentodemultiplos from "../imgs/agendamento/agendamentodemultiplos.jpg";
import confirmacao from "../imgs/agendamento/confirmacao.jpg";

import atendimentoeficiente from "../imgs/videochamada/atendimentoeficiente.jpg";
import interacoes from "../imgs/videochamada/interacoes.jpg";
import praticidade from "../imgs/videochamada/praticidade.jpg";
import conexaovisual from "../imgs/videochamada/conexaovisual.jpg";
import atendimentoremoto from "../imgs/videochamada/atendimentoremoto.jpg";
import ambientecolaborativovideochamada from "../imgs/videochamada/ambientecolaborativovideochamada.jpg";
import qualidade from "../imgs/videochamada/qualidade.jpg";

export const funcionalidades = [
  {
    image: iconChat,
    title: "ChatBot",
    subTitle:
      "Configure seu chatbot de forma rápida e fácil, tornando possível atender a milhares de pessoas de maneira eficiente e descomplicada.",
    text: "Venha transformar sua comunicação !",
    route: "/chatbot",
  },
  {
    image: iconChatInterno,
    title: "Chat Interno",
    subTitle:
      "Facilita a comunicação rápida e eficiente, estimulando a colaboração, agilizando a troca de informações e contribuindo para aprimorar o fluxo de trabalho.",
    text: "Venha elevar sua produtividade !",
    route: "/chatInterno",
  },
  {
    image: iconAgendamento,
    title: "Agendamento",
    subTitle:
      "Sistema de agendamento de consultas e serviços, proporcionando um agendamento automático, permitindo que marquem consultas de forma simples e sem filas.",
    text: "Venha inovar no seu sistema de agendamento !",
    route: "/agendamento",
  },
  {
    image: iconVideoChamada,
    title: "Videochamada",
    subTitle:
      "Flexibilidade e versatilidade, proporciona uma experiência de comunicação avançada, permitindo interações dinâmicas e enriquecedoras entre os participantes.",
    text: "Venha elevar sua produtividade com videochamadas !",
    route: "/videochamada",
  },
];

export const prefeituras = [
  {
    title: "Prefeitura de Sombrio",
    image: sombrioSlogam,
    link: "https://www.sombrio.sc.gov.br/",
  },
  {
    title: "Prefeitura de Navegantes",
    image: navegantesSlogam,
    link: "https://www.navegantes.sc.gov.br/",
  },
  {
    title: "Prefeitura de Gravataí",
    image: gravataiSlogam,
    link: "https://gravatai.atende.net/",
  },
  {
    title: "Prefeitura de Passo do Sobrado",
    image: passoDoSobradoSlogam,
    link: "https://passodosobrado.rs.gov.br/",
  },
  {
    title: "Prefeitura de Cascavel",
    image: cascavelSlogam,
    link: "https://cascavel.atende.net/cidadao",
  },
  {
    title: "Prefeitura de Francisco Beltrão",
    image: fransciscoBeltraoSlogam,
    link: "https://franciscobeltrao.pr.gov.br/",
  },
  {
    title: "Prefeitura de Dois Irmãos",
    image: doisIrmaosSlogam,
    link: "https://doisirmaos.atende.net/",
  },
  {
    title: "Prefeitura de Viamao",
    image: viamaoSlogam,
    link: "https://www.viamao.rs.gov.br/",
  },
  {
    title: "Prefeitura de Palmital",
    image: palmitalSlogam,
    link: "http://www.palmital.pr.gov.br/",
  },
  {
    title: "Prefeitura de Sarandi",
    image: sarandiSlogam,
    link: "https://www.sarandi.pr.gov.br/web/",
  },
  {
    title: "Prefeitura de Içara",
    image: icaraSlogam,
    link: "https://www.icara.sc.gov.br/",
  },
  {
    title: "Prefeitura de Jaguaruna",
    image: jaguarunaSlogam,
    link: "https://jaguaruna.sc.gov.br/",
  },
  {
    title: "Prefeitura de Rio Pardo",
    image: riopardoSlogam,
    link: "https://www.riopardo.rs.gov.br/",
  },
  {
    title: "Prefeitura de Gaspar",
    image: gasparSlogam,
    link: "https://www.gaspar.sc.gov.br/",
  },
  {
    title: "Prefeitura de Bituruna",
    image: biturunaSlogam,
    link: "https://bituruna.pr.gov.br/",
  },
];

export const formasdepagamento = [
  {
    image: cartaoDeCredito,
    title: "Cartão de Crédito",
  },
  {
    image: boleto,
    title: "Boleto",
  },
  {
    image: pix,
    title: "Pix",
  },
];

export const chatbotDetails = [
  {
    title:
      "Eficiência máxima ao seu alcance, com assistência rápida e inteligente que supera expectativas.",
    image: chatbotMockup,
    texto1:
      "Não perca tempo, tenha assistência rápida e inteligente sempre que precisar.",
    texto2: "O sucesso está à sua espera!",
  },
];
export const chatBot = [
  {
    title: "Atendentes",
    image: atendentes,
    subTitle:
      "Aprimore o atendimento ao cliente na sua organização, permitindo que múltiplos atendentes interajam e respondam a mensagens através do mesmo número de WhatsApp. Esta abordagem promove uma experiência mais rica e personalizada para seus clientes, ao mesmo tempo em que otimiza o fluxo de comunicação interna da empresa, impulsionando eficiência e produtividade.",
    text: "Potencialize sua equipe !",
  },
  {
    title: "Customização",
    image: customizacao,
    subTitle:
      "Com uma customização incrível para suas mensagens, é possível inserir emojis, imagens e até mesmo variáveis pré-programadas. Além disso, você tem total liberdade para responder as conversas da maneira que desejar, utilizando diferentes ações e identificadores de respostas, enriquecendo ainda mais sua interação.",
    text: "Eleve a personalização ao máximo !",
  },
  {
    title: "Fluxo de diálogo",
    image: fluxodedialogo,
    subTitle:
      "Transforme a maneira como você interage com os usuários, empregando a automação para gerenciar diálogos de forma eficiente e economizar tempo precioso. Personalize suas respostas e adapte-as ao contexto da conversa, garantindo uma experiência única e envolvente para cada usuário. Flexibilidade de responder da maneira que desejar, criando um ambiente de comunicação mais dinâmico e eficaz.",
    text: "Fluxo de diálogo intuitivo e eficiente !",
  },
  {
    title: "Grupos de atendimento",
    image: gruposdeatendimento,
    subTitle:
      " Aprimore a experiência do cliente e otimize a eficiência do seu serviço de atendimento ao segmentar e filtrar usuários com base em suas necessidades. Identifique-os com precisão e direcione-os ao grupo de atendentes mais qualificado para prestar assistência, proporcionando uma interação eficiente e personalizada. Esse método cuidadoso garante a satisfação do cliente e otimiza o fluxo de trabalho da equipe.",
    text: "Atendimento ágil com grupos especializados !",
  },
  {
    title: "Protocolos",
    image: protocolos,
    subTitle:
      "Ofereça a seus usuários uma experiência de atendimento organizada e estruturada, entregando protocolos de atendimento personalizados. Com isso, é possível monitorar e gerenciar as conversas de forma eficiente, acompanhando cada interação por meio dos protocolos estabelecidos. Essa abordagem meticulosa garante um atendimento mais claro, transparente e rastreável, elevando a satisfação do cliente.",
    text: "Protocolos claros para um atendimento eficiente !",
  },
  {
    title: "Pesquisa de Satisfação",
    image: pesquisadesatisfacao,
    subTitle:
      "Desenvolva variadas pesquisas de satisfação para avaliar o desempenho dos seus atendentes com base nas respostas do seu público. Posteriormente, analise os resultados detalhadamente por meio de relatórios formatados, permitindo um entendimento aprofundado do desempenho e áreas de melhoria.",
    text: "Feedback com pesquisas rápidas !",
  },
  {
    title: "Grupos de mensagem",
    image: gruposdemensagem,
    subTitle:
      "Aprimore a eficiência do seu serviço utilizando respostas automatizadas ativadas por eventos pré-programados, como  início, pós avaliação, e finalização do atendimento, e fora do horário de atendimento. Isso assegura uma comunicação eficaz e adaptada a diferentes contextos, melhorando a experiência do usuário.",
    text: "Eficiência na comunicação!",
  },
  {
    title: "Variáveis",
    image: variaveis,
    subTitle:
      "Incorpore variáveis personalizadas, como nome, e-mail e outros dados relevantes, ao perfil de cada usuário que entra em contato com você. Essa abordagem enriquece a experiência de atendimento, possibilitando a criação de interações altamente personalizadas e eficazes.  Otimize o processo de comunicação, aprimorando o relacionamento com os usuários e potencializando a eficácia do serviço.",
    text: "Variáveis personalizadas: atendimento único !",
  },
  {
    title: "Mensagens Gerais",
    image: mensagensgerais,
    subTitle:
      "Implemente comandos soberanos que proporcionam a flexibilidade de alterar o fluxo de diálogo para qualquer ponto desejado durante a interação com o bot. Essa funcionalidade permite que você ajuste dinamicamente a conversa, garantindo uma experiência de atendimento mais fluida e adaptativa às necessidades específicas do usuário em tempo real.",
    text: "Mensagens gerais: comunicação ampla !",
  },
  {
    title: "Relatórios",
    image: relatorios,
    subTitle:
      "Utilize um dashboard de relatórios avançado para monitorar o desempenho de atendentes e grupos de atendimento com base no tempo médio de atendimento em cada dia e na quantidade total de atendimentos encerrados diariamente. Uma visão detalhada e precisa da eficiência e produtividade do serviço, permitindo a tomada de decisões informadas e aprimoramento contínuo do atendimento ao cliente.",
    text: "Relatório completo para insights precisos !",
  },
  {
    title: "Campanhas e Disparos",
    image: campanhasedisparos,
    subTitle:
      "Gerencie o envio de mensagens em massa para seus contatos na plataforma com a opção de aguardar ou não por uma resposta. Essa funcionalidade oferece flexibilidade no gerenciamento das comunicações, permitindo que você alcance um grande número de usuários de forma eficiente, independente da necessidade de interação subsequente.",
    text: "Envios em massa flexíveis !",
  },
];

export const chatinternoDetails = [
  {
    title:
      "Maximize a produtividade com nosso chat interno, onde a rapidez e inteligência andam juntas.",
    image: chatinternoMockup,
    texto1: "Acelere seu caminho para o sucesso com nosso chat interno.",
    texto2: "A excelência está à sua disposição !",
  },
];
export const chatinterno = [
  {
    title: "Agilidade",
    image: agilidade,
    subTitle:
      "Garanta uma comunicação eficiente e em tempo real, eliminando quaisquer atrasos e acelerando as trocas de informações. Esse método de comunicação rápido e dinâmico melhora a colaboração entre os participantes e otimiza a tomada de decisões, proporcionando uma experiência de comunicação mais produtiva e satisfatória para todos os envolvidos.",
    text: "Rapidez e eficiência ao seu alcance !",
  },
  {
    title: "Colaboração Eficiente",
    image: colaboracaoeficiente,
    subTitle:
      "O chat interno simplifica a interação e colaboração entre equipes, resultando em uma comunicação mais rápida e eficiente. Isso promove um ambiente de trabalho integrado e dinâmico, onde informações são trocadas e conhecimentos compartilhados, aumentando a produtividade e impulsionando o sucesso da organização.",
    text: "Colaboração: união e sucesso !",
  },
  {
    title: "Resolução Ágil",
    image: resolucaoagil,
    subTitle:
      "Ferramentas ágeis e eficientes, as dúvidas e questões que surgem no ambiente de trabalho podem ser resolvidas de forma rápida e direta, eliminando longos processos e burocracias. Essa abordagem dinâmica promove um fluxo de trabalho mais eficaz, otimiza o tempo da equipe e contribui para o aumento da produtividade e satisfação no ambiente de trabalho.",
    text: "Resolva dúvidas de forma rápida e eficiente !",
  },
  {
    title: "Compartilhamento Dinâmico",
    image: compartilhamentodinamico,
    subTitle:
      "Funcionalidade de troca de arquivos e documentos de maneira ágil e intuitiva melhora significativamente a comunicação e colaboração entre as equipes. Isso agiliza o fluxo de trabalho, acelerando o compartilhamento de informações cruciais e otimizando a realização das tarefas. Processo eficiente de compartilhamento dinâmico de documentos contribui para aumentar a produtividade. ",
    text: "Agilize com compartilhamento eficaz !",
  },
  {
    title: "Ambiente Colaborativo",
    image: ambientecolaborativo,
    subTitle:
      " Ao promover a troca eficaz de informações e o trabalho conjunto, esta ferramenta contribui significativamente para a melhoria das relações entre as equipes, gerando um ambiente de trabalho mais colaborativo, onde o compartilhamento de ideias e a resolução de desafios ocorrem de maneira harmoniosa e produtiva, elevando a eficiência e a satisfação no local de trabalho.",
    text: "Promova a colaboração e fortaleça as equipes !",
  },
  {
    title: "Comunicação Inovadora",
    image: comunicacaoinovadora,
    subTitle:
      "Oferece um ambiente de comunicação mais informal e descontraído, o que favorece a expressão livre de ideias e opiniões, estimulando a criatividade e a inovação. O diálogo aberto e a interação descontraída facilitam a geração de insights inovadores, promovendo um ambiente mais dinâmico e propício para o surgimento de soluções criativas.",
    text: "Criatividade fluída com comunicação descontraída !",
  },
  {
    title: "Economia de Custos",
    image: economiadecustos,
    subTitle:
      "Promove a redução de custos operacionais ao minimizar deslocamentos e chamadas telefônicas, facilitando a troca de informações e o compartilhamento de dados de forma remota. Isso permite que a equipe economize tempo e recursos, resultando em um ambiente de trabalho mais produtivo e sustentável.",
    text: "Economia significativa de custos ! ",
  },
  {
    title: "Reuniões Dinâmicas",
    image: reunioesdinamicas,
    subTitle:
      "Transforme as reuniões em momentos mais dinâmicos e produtivos, incentivando a participação ativa dos envolvidos e o compartilhamento de ideias de forma criativa. Ao criar um ambiente mais interativo e engajador, as reuniões se tornam mais eficazes e eficientes, promovendo uma troca de conhecimentos mais rica e uma tomada de decisões mais assertiva.",
    text: "Reuniões mais eficazes e produtivas !",
  },
  {
    title: "Comunicação Direta e Eficaz",
    image: comunicacaodiretaeficaz,
    subTitle:
      "Aprimore a forma como você se comunica, adotando uma abordagem mais direta e eficiente que elimina barreiras e ruídos na comunicação. Isso promove uma interação mais clara e objetiva, garantindo que as mensagens sejam transmitidas e compreendidas de forma rápida e precisa, melhorando a qualidade do diálogo e otimizando o processo de tomada de decisões.",
    text: "Comunicação eficiente e direta !",
  },
];

export const agendamentoDetails = [
  {
    title:
      "Potencialize a eficiência do agendamento com nossa solução ágil e inteligente, simplificando sua vida.",
    image: agendamentoMockup,
    texto1:
      "Impulsione sua jornada rumo ao sucesso com nosso sistema de agendamento.",
    texto2: "Agende com facilidade: a excelência sem filas!",
  },
];
export const agendamento = [
  {
    title: "Cadastro ilimitado",
    image: cadastroilimitado,
    subTitle:
      "Nossa plataforma oferece um cadastro ilimitado de profissionais, permitindo o registro de informações como nome, foto, contato, departamento, setor, especialidade e registro profissional.  Isso simplifica a gestão de recursos humanos e otimiza os processos internos, proporcionando uma solução eficiente e organizada para a sua empresa.",
    text: "Cadastro ilimitado com todos os detalhes essenciais!",
  },
  {
    title: "Gestão de Locais de Atendimento",
    image: locais,
    subTitle:
      "A nossa ferramenta proporciona um método fácil e eficaz para o cadastro de locais de atendimento, permitindo que você insira informações detalhadas, como endereço e horário de funcionamento. Isso não só facilita a organização e a gestão desses locais, mas também garante que as informações estejam sempre atualizadas e acessíveis.",
    text: "Gestão: completa e única !",
  },
  {
    title: "Gestão de Especialidades para Atendimento",
    image: especialidades,
    subTitle:
      "Nossa plataforma oferece a capacidade de cadastrar diversas especialidades para atendimento, ampliando as opções disponíveis para os clientes. Isso facilita a gestão de serviços e garante que as necessidades específicas dos clientes sejam atendidas de forma eficiente e personalizada, contribuindo para uma experiência mais completa e satisfatória.",
    text: "Cadastre e amplie especialidades !",
  },
  {
    title: "Plataforma web e App",
    image: plataformaweb,
    subTitle:
      "Nossa solução fornece um aplicativo e uma plataforma web personalizados que permitem aos clientes agendar horários diretamente na organização, simplificando o processo e proporcionando uma experiência mais eficiente e personalizada. Essa funcionalidade contribui para a otimização dos processos internos e uma gestão mais eficaz da organização.",
    text: "Agende horários com facilidade na organização !",
  },
  {
    title: "Plataforma personalizável",
    image: personalizavel,
    subTitle:
      "A nossa plataforma é esteticamente personalizável, permitindo que você adapte a aparência da plataforma ao design da sua organização. Isso proporciona uma experiência visual mais integrada e coesa, além de fortalecer a identidade da sua marca. Você tem a liberdade de personalizar a plataforma de acordo com suas preferências de design, garantindo uma experiência de usuário mais agradável e profissional.",
    text: "Plataforma personalizável ao seu estilo !",
  },
  {
    title: "Visualização completa",
    image: vizualizacaocompleta,
    subTitle:
      "A nossa plataforma oferece a opção de visualizar os horários marcados em formatos de tabela e agenda, proporcionando uma organização mais eficiente e intuitiva. Isso facilita a compreensão e o gerenciamento dos compromissos, contribuindo para uma experiência mais otimizada e organizada para os usuários.",
    text: "Organize horários em tabela ou agenda !",
  },
  {
    title: "Agendamento de múltiplos",
    image: agendamentodemultiplos,
    subTitle:
      "A nossa plataforma oferece a capacidade de agendar múltiplos serviços, com notificações automáticas via e-mail e WhatsApp em intervalos variados. Essa funcionalidade proporciona uma experiência mais eficiente e organizada, pois facilita o gerenciamento de compromissos e garante que você seja informado sobre eles de forma ágil e personalizada.",
    text: "Agende múltiplos serviços com notificações rápidas !",
  },
  {
    title: "Personalização de horários",
    image: horarios,
    subTitle:
      "A nossa plataforma oferece a possibilidade de personalizar os horários de atendimento conforme as preferências de cada profissional, especialidade e local. Isso facilita a gestão da agenda e proporciona uma experiência mais adaptada e eficiente, tanto para os profissionais quanto para os clientes, otimizando o tempo de trabalho e garantindo um atendimento personalizado.",
    text: "Adapte horários por profissional, especialidade e local !",
  },
  {
    title: "Confirmação de agendamento",
    image: confirmacao,
    subTitle:
      "Após a realização de um agendamento, a nossa plataforma envia automaticamente confirmações para os clientes por e-mail e/ou WhatsApp. Essa funcionalidade garante uma comunicação eficiente e segura, fornecendo aos clientes os detalhes necessários sobre o agendamento de forma rápida e conveniente, contribuindo para uma experiência mais organizada e satisfatória.",
    text: "Receba confirmações de agendamento por e-mail e/ou WhatsApp !",
  },
];

export const videochamadaDetails = [
  {
    title: "Comunicação eficiente e fácil com videochamadas em um clique.",
    image: videochamadaMockup,
    texto1: "Acelere o sucesso com videochamadas em um clique.",
    texto2: "Videochamadas excepcionais à sua disposição!",
  },
];
export const videochamada = [
  {
    title: "Conexão visual",
    image: conexaovisual,
    subTitle:
      "As videochamadas proporcionam uma conexão visual entre profissionais, independentemente de sua localização, facilitando a comunicação e colaboração em projetos. Isso possibilita interações mais ricas e dinâmicas, eliminando barreiras geográficas e aproximando equipes de trabalho.",
    text: "Próximos, mesmo à distância !",
  },
  {
    title: "Atendimento remoto eficiente",
    image: atendimentoeficiente,
    subTitle:
      "Uma ferramenta versátil que pode ser utilizada em diversas áreas, como consultas médicas e orientação jurídica. Elas proporcionam uma forma eficaz e prática de atendimento à distância, facilitando o acesso a serviços especializados e otimizando o tempo de profissionais e clientes.",
    text: "Atendimento remoto: praticidade e eficiência !",
  },
  {
    title: "Atendimento Remoto Mais Pessoal",
    image: atendimentoremoto,
    subTitle:
      "Proporcionam uma experiência de atendimento remoto mais próxima e pessoal, permitindo interações humanas significativas e uma comunicação eficaz, independentemente da distância física.Isso possibilita um atendimento mais empático e individualizado, estreitando o relacionamento entre profissionais e clientes.",
    text: "Atendimento remoto mais próximo e pessoal !",
  },
  {
    title: "Interações remotas aprimoradas",
    image: interacoes,
    subTitle:
      "Tornam as interações remotas mais envolventes e eficazes, proporcionando uma comunicação face a face virtual que aproxima os participantes e permite uma troca de informações mais rica e dinâmica. Com essa ferramenta, é possível compartilhar documentos, realizar apresentações e colaborar em projetos em tempo real, independentemente da distância física.",
    text: "Interações remotas mais envolventes !",
  },
  {
    title: "Ambiente Colaborativo",
    image: ambientecolaborativovideochamada,
    subTitle:
      "Ao promover a troca eficaz de informações e o trabalho conjunto, esta ferramenta contribui significativamente para a melhoria das relações entre as equipes, gerando um ambiente de trabalho mais colaborativo, onde o compartilhamento de ideias e a resolução de desafios ocorrem de maneira harmoniosa e produtiva, elevando a eficiência e a satisfação no local de trabalho.",
    text: "Promova a colaboração e fortaleça as equipes !",
  },
  {
    title: "Praticidade ao atendimento",
    image: praticidade,
    subTitle:
      "Trazem praticidade e eficiência ao atendimento remoto, proporcionando uma comunicação ágil e efetiva entre profissionais e clientes. Com essa ferramenta, é possível oferecer consultas, tirar dúvidas e resolver problemas de forma rápida e conveniente, sem a necessidade de deslocamento físico.",
    text: "Atendimento remoto sem deslocamentos desnecessários !",
  },
  {
    title: "Qualidade excepcional",
    image: qualidade,
    subTitle:
      "A nossa plataforma oferece qualidade superior para suas videochamadas, proporcionando uma experiência de comunicação clara, estável e sem interrupções. Com essa ferramenta, você pode realizar chamadas de vídeo com alta resolução de imagem e som, garantindo uma interação mais rica e dinâmica entre os participantes.",
    text: "Videochamadas de alta qualidade ! ",
  },
];

export const card = [
  {
    icon: iconChat,
    title: "ChatBot",
    imageSelo: imageSelo,
    text: "Configure seu chatbot de forma rápida e fácil, tornando possível atender a milhares de pessoas de maneira eficiente e descomplicada.",
  },
  {
    icon: iconChatInterno,
    title: "Chat Interno",
    imageSelo: imageSelo,
    text: "Facilita a comunicação rápida e eficiente, estimulando a colaboração, agilizando a troca de informações e contribuindo para aprimorar o fluxo de trabalho",
  },
  {
    icon: iconAgendamento,
    title: "Agendamento",
    imageSelo: imageSelo,
    text: "Sistema de agendamento de consultas e serviços, proporcionando um agendamento automático, permitindo que marquem consultas de forma simples e sem filas.",
  },
  {
    icon: iconVideoChamada,
    title: "Videochamada",
    imageSelo: imageSelo,
    text: "Flexibilidade e versatilidade, proporciona uma experiência de comunicação avançada, permitindo interações dinâmicas e enriquecedoras entre os participantes.",
  },
];

export const Tags = [
  {
    title: "Eficiência e Agilidade",
    text: "Reduza o tempo de espera e agilize as interações com respostas automáticas para perguntas frequentes.",
  },
  {
    title: "Economia de Recursos",
    text: "Libere sua equipe para focar em tarefas complexas, enquanto o chatbot lida com solicitações rotineiras de forma simultânea.",
  },
  {
    title: "Facilidade de Uso",
    text: "O WhatsApp é uma plataforma amplamente utilizada, e os contribuintes já estão familiarizados com ela. Oferecer atendimento automatizado nesse canal facilita a interação e reduz barreiras de entrada.",
  },
  {
    title: "Experiência do Usuário Aprimorada",
    text: "Aproveite a familiaridade do WhatsApp para uma interação fácil e intuitiva, melhorando a experiência geral dos contribuintes.",
  },
  {
    title: "Redução de Carga de Trabalho",
    text: "O atendimento automatizado pode lidar com tarefas rotineiras e repetitivas, liberando a equipe para lidar com questões mais complexas e demandas que exigem atenção humana.",
  },
  {
    title: "Coleta de Dados e Feedback",
    text: "Um chatbot pode coletar dados importantes sobre as interações dos contribuintes, fornecendo insights valiosos para aprimorar os serviços e identificar áreas de melhoria.",
  },
];
