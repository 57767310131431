import React from "react";
import { useNavigate } from "react-router-dom";
import bot from "../../imgs/backgrounds/bot.svg";
import ButtonSaibaMais from "../ButtonSaibaMais";
import * as S from "./styles";

export default function BannerProximoPasso() {
  const navigate = useNavigate();
  const smoothScroll = (path) => {
    navigate(path);
  };
  return (
    <S.Container>
      <S.ContainerAlign>
        <S.Space>
          <S.ContainerSpaceTextos>
            <S.AlignTextos>
              <S.Title>Pronto para seu</S.Title>
              <S.subtitle>Proximo passo ?</S.subtitle>
            </S.AlignTextos>
            <S.Text>
              Nosso compromisso é elevar a excelência em cada produto,
              ultrapassar expectativas e encantar nossos clientes, criando
              experiências que os fascinem de maneira única.
            </S.Text>
            <S.ContainerButton>
              <ButtonSaibaMais
                backgroundcolorhover="#00365f"
                title="Fale com a equipe"
                colorhover="white"
                boxshadowhover="white"
                onClick={() => smoothScroll("/contato")}
              />
            </S.ContainerButton>
          </S.ContainerSpaceTextos>
          <S.ContainerSVG>
            <S.IconSVG src={bot} alt="icone do bot" />
          </S.ContainerSVG>
        </S.Space>
      </S.ContainerAlign>
    </S.Container>
  );
}
