import React from "react";
import * as S from "./styles";

export default function ButtonSaibaMais({
  title,
  color,
  onClick,
  backgroundcolorhover,
  colorhover,
  boxshadowhover,
}) {
  return (
    <S.Container onClick={onClick}>
      <S.Button
        color={color}
        colorhover={colorhover}
        backgroundcolorhover={backgroundcolorhover}
        boxshadowhover={boxshadowhover}
        type="button"
      >
        {title}
      </S.Button>
    </S.Container>
  );
}
