import React, { useState } from "react";
import imgBannerVox from "../../../../imgs/backgrounds/logoVox.png";
import IntroducaoText from "../../../../components/IntroducaoText";
import ButtonSaibaMais from "../../../../components/ButtonSaibaMais";
import ButtonContact from "../../../../components/ButtonContact";
import * as S from "./styles";

export default function Formulario() {
  const [showModal, setShowModal] = useState(false);
  const [showModalError, setShowModalError] = useState(false);

  const [form, setForm] = useState({
    nome: "",
    celular: "",
    email: "",
    mensagem: "",
  });

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const resetForm = () => {
    setForm({
      nome: "",
      celular: "",
      email: "",
      mensagem: "",
    });
  };

  const handleSubmit = async () => {
    const isFormValid = Object.values(form).every(
      (value) => value.trim() !== ""
    );
    if (isFormValid) {
      try {
        const response = await fetch(
          "http://cronos-env.eba-db4ehnbp.us-east-1.elasticbeanstalk.com/contactUs",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ form }),
          }
        );
        if (response.ok) {
          resetForm();
          setShowModal(true);
        } else {
          resetForm();
          console.error(`Erro ao enviar o formulário, tente novamente!`);
        }
      } catch (error) {
        console.error("Erro ao enviar o formulário:", error);
      }
    } else {
      setShowModalError(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowModalError(false);
  };

  return (
    <S.Container id="contato">
      <IntroducaoText
        title="Queremos falar com você"
        subtitle="Preencha o formulário, e logo entraremos em contato para ajudar!"
        subtitleFontSize="25px"
      />
      <S.ContainerForm>
        <S.InputComponentContainer>
          <S.Label htmlFor="nome">Nome</S.Label>
          <S.InputContainer>
            <S.InputIcon color="#00365f" />
            <S.Input
              name="nome"
              type="text"
              onChange={onChange}
              value={form.nome}
              placeholder="Nome"
            />
          </S.InputContainer>
        </S.InputComponentContainer>

        <S.DoubleInputContainer>
          <S.InputComponentContainer>
            <S.Label htmlFor="celular">Celular</S.Label>
            <S.InputContainer>
              <S.InputIconPhone color="#00365f" />
              <S.Input
                name="celular"
                onChange={onChange}
                value={form.celular}
                placeholder="Celular"
                type="tel"
                maxLength={11}
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                }
                required
              />
            </S.InputContainer>
          </S.InputComponentContainer>

          <S.InputComponentContainer>
            <S.Label htmlFor="email">Email</S.Label>
            <S.InputContainer>
              <S.InputIconEmail color="#00365f" />
              <S.Input
                name="email"
                onChange={onChange}
                value={form.email}
                placeholder="Email"
                type="email"
              />
            </S.InputContainer>
          </S.InputComponentContainer>
        </S.DoubleInputContainer>

        <S.InputComponentContainer>
          <S.Label htmlFor="mensagem">Mande uma mensagem</S.Label>
          <S.InputContainer>
            <S.InputIconMessage color="#00365f" />
            <S.InputTextArea
              name="mensagem"
              type="text"
              onChange={onChange}
              value={form.mensagem}
              placeholder="Nos diga como podemos ajudar você .."
            />
          </S.InputContainer>
        </S.InputComponentContainer>

        <S.ContainerButton>
          <ButtonSaibaMais
            onClick={handleSubmit}
            backgroundcolorhover="#9AC31C"
            color="#00365f"
            title="Enviar mensagem"
          />
        </S.ContainerButton>

        {showModal && (
          <S.ModalOverlay>
            <S.ModalContent>
              <S.CloseButton onClick={handleCloseModal}>X</S.CloseButton>
              <S.imageLogo src={imgBannerVox} />
              <S.TitleTextModal>
                Recebemos suas informações. Para um contato mais rápido, por
                favor, entre em contato com nossa equipe.
              </S.TitleTextModal>
              <ButtonContact
                title={"Fale com a equipe"}
                url="https://wa.me/+554834782300"
              />
            </S.ModalContent>
          </S.ModalOverlay>
        )}

        {showModalError && (
          <S.ModalOverlay>
            <S.ModalContent>
              <S.CloseButton onClick={handleCloseModal}>X</S.CloseButton>
              <S.imageLogo src={imgBannerVox} />
              <S.TitleTextModal>
                Preencha todos os campos obrigatórios antes de enviar.
              </S.TitleTextModal>
              <ButtonContact
                title={"Fale com a equipe"}
                url="https://wa.me/+554834782300"
              />
            </S.ModalContent>
          </S.ModalOverlay>
        )}
      </S.ContainerForm>
    </S.Container>
  );
}
