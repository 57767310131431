import React from "react";
import * as S from "./styles";

export default function ButtonContact({ title, url }) {
  const handleButtonClick = () => {
    window.open(url, "_blank");
  };
  return (
    <S.Button onClick={handleButtonClick} type="button">
      {title}
    </S.Button>
  );
}
