import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const Nav = styled.nav`
  width: 90%;
  padding: 1rem;
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

export const Ul = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style-type: none;
  flex-wrap: wrap;
  margin: 0px;
  padding: 0px;
  @media (max-width: 768px) {
    width: 95%;
  }
`;

export const Img = styled.img`
  width: 250px;
  height: 140px;
  max-width: 250px;
  max-height: 140px;
  object-fit: cover;
`;

export const TagsSpacing = styled.div`
  display: flex;
  align-items: center;
  gap: 70px;
  flex-wrap: wrap;
  @media (max-width: 1200px) {
    gap: 30px;
    margin-right: 30px;
  }
`;

export const Tag = styled(Link)`
  font-weight: bold;
  font-size: 20px;
  color: #00365f;
  text-decoration: none;
  &:hover {
    color: #9ac31c;
  }
  @media (max-width: 1340px) {
    font-size: 18px;
  }
`;

export const DesktopMenu = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const MobileMenu = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

export const MobileMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: white;
  list-style-type: none;
  gap: 30px;
  position: absolute;
  width: 78%;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;
