import styled, { keyframes } from "styled-components";

const zoomIn = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
`;

export const ContainerCard = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    margin: 10px;
  }
`;

export const ContainerAlignCards = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: row;
  margin: 20px;
  gap: 20px;
  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin: 30px;
  }
`;

export const ContainerImageBorder = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  order: ${(props) => (props.index % 2 === 0 ? 1 : 2)};
  border: 1px solid #eeeeee;
  border-radius: 16px;
  cursor: pointer;
  overflow: hidden;
  transition: border-color 0.3s ease-in-out;
  &:hover {
    cursor: pointer;
    & > img {
      animation: ${zoomIn} 0.3s ease-in-out forwards;
    }
  }
  @media (max-width: 768px) {
    order: 1;
  }
`;

export const Image = styled.img`
  object-fit: cover;
  width: 450px;
  padding: 5px;
  @media (max-width: 768px) {
    width: 328px;
    padding: 10px;
  }
  transition: transform 0.5s cubic-bezier(0.47, 0, 0.745, 0.715);
`;

export const ContainerButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Button = styled.button`
  position: absolute;
  font-family: "Raleway", sans-serif;
  bottom: 10px;
  padding: 12px;
  background-color: white;
  border: 1px solid #eeeeee;
  border-radius: 7px;
  color: #00365f;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 5px;
  &:hover {
    background-color: #9ac31c;
    color: white;
    box-shadow: 0px 8px 16px 0px #00365f;
    transition: all 0.3s ease;
    border: none;
  }
  @media (max-width: 768px) {
    bottom: 9px;
    padding: 10px;
    font-size: 14px;
  }
`;

export const ContainerTexts = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  border-radius: 16px;
  order: ${(props) => (props.index % 2 === 0 ? 2 : 1)};
  background-color: #00365f;
  padding: 12px;
  width: 50%;
  &:hover {
    box-shadow: 0px 0px 16px 0px #00365f;
    transition: all 0.3s ease;
  }
  @media (max-width: 1000px) {
    height: auto;
    width: 80%;
    order: 2;
  }
  @media (max-width: 768px) {
    height: auto;
    width: 100%;
  }
`;

export const Title = styled.p`
  font-size: 23px;
  color: white;
  font-weight: bolder;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const SubTitle = styled.p`
  font-size: 23px;
  color: white;
  font-weight: 300;
  margin: 0;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const Text = styled.p`
  font-size: 18px;
  color: white;
  @media (max-width: 768px) {
    text-align: center;
  }
`;
