import React, { useState } from "react";
import imageSelo from "../../imgs/imgscards/icons-cards/img-certified.svg";
import { Tags } from "../../data";
import * as S from "./styles";

export default function Accordion() {
  const [openSection, setOpenSection] = useState(null);

  const toggleSection = (index) => {
    setOpenSection(openSection === index ? null : index);
  };

  return (
    <S.Container>
      <S.ContainerSpaceAccordions>
        {Tags.map((tag, index) => (
          <S.AccordionContainer
            key={index}
            onClick={() => toggleSection(index)}
            active={openSection === index}
          >
            <S.ContainerAlignAccordion>
              <S.ContainerSpace>
                <S.Image src={imageSelo} />
                <S.title>{tag.title}</S.title>
              </S.ContainerSpace>
              <S.ContainerIcon
                onClick={() => toggleSection(index)}
                active={openSection === index}
              >
                {openSection === index ? (
                  <S.IconArrowSecundario size={40} />
                ) : (
                  <S.IconArrow size={40} />
                )}
              </S.ContainerIcon>
            </S.ContainerAlignAccordion>
            <S.AccordionContent active={openSection === index}>
              {tag.text}
            </S.AccordionContent>
          </S.AccordionContainer>
        ))}
      </S.ContainerSpaceAccordions>
    </S.Container>
  );
}
