import React, { useState } from "react";
import IntroducaoText from "../IntroducaoText";
import { prefeituras } from "../../data";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import * as S from "./styles";

const CarrouselItem = ({ item, index }) => (
  <a href={item.link} rel="noopener noreferrer" target="_blank">
    <S.CarouselItem key={index} src={item.image} title={item.title} />
  </a>
);

export default function Carrousel() {
  const [startIndex, setStartIndex] = useState(0);
  const imagesPerPage = 7;

  const nextSlide = () => {
    setStartIndex((prevIndex) =>
      Math.min(prevIndex + 1, prefeituras.length - imagesPerPage)
    );
  };

  const prevSlide = () => {
    setStartIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const renderCarouselItem = (item, index) => (
    <CarrouselItem key={index} item={item} index={index} />
  );

  return (
    <S.Container>
      <IntroducaoText
        title="Venha conhecer"
        subtitle="Clientes de sucesso"
        titleColor="#9AC31C"
      />
      <S.ContainerCarousel>
        <S.CarouselContainer>
          <S.CarouselButton onClick={prevSlide}>
            <BsChevronLeft color="#00365f" />
          </S.CarouselButton>
          {prefeituras
            .slice(startIndex, startIndex + imagesPerPage)
            .map(renderCarouselItem)}
          <S.CarouselButton onClick={nextSlide}>
            <BsChevronRight color="#00365f" />
          </S.CarouselButton>
        </S.CarouselContainer>
      </S.ContainerCarousel>
    </S.Container>
  );
}
