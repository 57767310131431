import React, { useState, useEffect } from "react";
import { chatBot, chatbotDetails } from "../../data";
import chatbotImg from "../../imgs/imgsMockupBanner/chatbot.svg";
import NavBar from "../../components/NavBar";
import TextoIntroducaoFuncionalidade from "../../components/TextoIntroducaoFuncionalidade";
import CardFuncionalidade from "../../components/CardFuncionalidade";
import ButtonContact from "../../components/ButtonContact";
import ButtonFlutuante from "../../components/ButtonFlutuante";
import Footer from "../../components/Footer";
import * as S from "./styles";

export default function ChatBot() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);
  window.scrollTo(0, 0);
  return (
    <>
      {isLoading && (
        <S.LoadingContainer>
          <S.LoadingSpinner />
        </S.LoadingContainer>
      )}
      {!isLoading && (
        <S.Container>
          {chatbotDetails.map((item, index) => (
            <S.ContainerPosition key={index}>
              <NavBar isTabs={false} />
              <TextoIntroducaoFuncionalidade title={item.title} />
              <S.ContainerDuplo>
                <S.ImageMockup src={item.image} />
                <S.ContainerTextosColumn>
                  <S.TitleInformation>{item.texto1}</S.TitleInformation>
                  <S.subTitle>{item.texto2}</S.subTitle>
                </S.ContainerTextosColumn>
              </S.ContainerDuplo>
              <S.Title>
                Descubra os benefícios exclusivos do nosso chatbot!
              </S.Title>
            </S.ContainerPosition>
          ))}
          {chatBot.map((item, index) => (
            <CardFuncionalidade key={index} card={item} index={index} />
          ))}
          <S.BackgroundImage>
            <S.ContainerDuplo>
              <S.Image src={chatbotImg} />
              <S.ContainerTextosColumn>
                <S.TitleInformation>CHATBOT</S.TitleInformation>
                <S.TitleInformationSecundario>
                  INTELIGENTE
                </S.TitleInformationSecundario>
                <S.subTitleInformation>RÁPIDO </S.subTitleInformation>
                <S.subTitleInformation>EFICIENTE </S.subTitleInformation>
                <S.subTitleInformation>PERSONALIZADO</S.subTitleInformation>
                <S.ContainerButton>
                  <ButtonContact
                    title="Comece agora"
                    url="https://wa.me/+554834782300"
                  />
                </S.ContainerButton>
              </S.ContainerTextosColumn>
            </S.ContainerDuplo>
          </S.BackgroundImage>
          <Footer />
          <ButtonFlutuante />
        </S.Container>
      )}
    </>
  );
}
