import React, { useEffect, useState } from "react";
import { videochamada, videochamadaDetails } from "../../data";
import videochamadaImg from "../../imgs/imgsMockupBanner/videochamada.svg";
import NavBar from "../../components/NavBar";
import TextoIntroducaoFuncionalidade from "../../components/TextoIntroducaoFuncionalidade";
import CardFuncionalidade from "../../components/CardFuncionalidade";
import ButtonContact from "../../components/ButtonContact";
import ButtonFlutuante from "../../components/ButtonFlutuante";
import Footer from "../../components/Footer";
import * as S from "./styles";

export default function VideoChamada() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, []);
  window.scrollTo(0, 0);

  return (
    <>
      {isLoading && (
        <S.LoadingContainer>
          <S.LoadingSpinner />
        </S.LoadingContainer>
      )}
      {!isLoading && (
        <S.Container>
          {videochamadaDetails.map((item, index) => (
            <S.ContainerPosition key={index}>
              <NavBar isTabs={false} />
              <TextoIntroducaoFuncionalidade title={item.title} />
              <S.ContainerDuplo>
                <S.ImageMockup src={item.image} />
                <S.ContainerTextosColumn>
                  <S.TitleInformation>{item.texto1}</S.TitleInformation>
                  <S.subTitle>{item.texto2}</S.subTitle>
                </S.ContainerTextosColumn>
              </S.ContainerDuplo>
              <S.Title>
                Descubra os benefícios exclusivos do nosso chatbot !
              </S.Title>
            </S.ContainerPosition>
          ))}
          {videochamada.map((item, index) => (
            <CardFuncionalidade
              key={index}
              card={item}
              index={index}
              widthImage="290px"
              containerHeigth="300px"
              containerHeigthText="290px"
            />
          ))}
          <S.BackgroundImage>
            <S.ContainerDuplo>
              <S.Image src={videochamadaImg} />
              <S.ContainerTextosColumn>
                <S.TitleInformation>VIDEO CHAMADA</S.TitleInformation>
                <S.TitleInformationSecundario>
                  INTELIGENTE
                </S.TitleInformationSecundario>
                <S.subTitleInformation>CLAREZA </S.subTitleInformation>
                <S.subTitleInformation>FACILIDADE </S.subTitleInformation>
                <S.subTitleInformation>
                  QUALIDADE SUPERIOR
                </S.subTitleInformation>
                <S.ContainerButton>
                  <ButtonContact
                    title="Comece agora"
                    url="https://wa.me/+554834782300"
                  />
                </S.ContainerButton>
              </S.ContainerTextosColumn>
            </S.ContainerDuplo>
          </S.BackgroundImage>
          <Footer />
          <ButtonFlutuante />
        </S.Container>
      )}
    </>
  );
}
