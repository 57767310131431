import React, { useEffect } from "react";
import * as S from "./styles";

export default function CardFuncionalidade({ card, index }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { image, title, subTitle, text } = card;
  return (
    <S.ContainerCard>
      <S.ContainerAlignCards key={index}>
        <S.ContainerImageBorder index={index}>
          <S.Image src={image} />
        </S.ContainerImageBorder>
        <S.ContainerTexts>
          <S.Title>{title}</S.Title>
          <S.SubTitle>{subTitle}</S.SubTitle>
          <S.Text>{text}</S.Text>
        </S.ContainerTexts>
      </S.ContainerAlignCards>
    </S.ContainerCard>
  );
}
