import styled from "styled-components";
import background from "../../imgs/backgrounds/fundoSecundario.png";

export const Container = styled.div`
  display: flex;
  height: auto;
  background-image: url(${background});
`;

export const ContainerAlign = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 40px;
  @media (max-width: 768px) {
    margin: 20px;
  }
`;

export const Space = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  @media (max-width: 937px) {
    flex-direction: column;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    margin: 30px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;

export const ContainerSpaceTextos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: 937px) {
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
    margin: 20px;
  }
`;

export const AlignTextos = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  @media (max-width: 937px) {
    align-items: center;
    justify-content: center;
  }
`;

export const Title = styled.p`
  margin: 0;
  font-size: 36px;
  font-weight: normal;
  color: #00365f;
  text-align: left;
  @media (max-width: 937px) {
    text-align: center;
  }
  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const subtitle = styled.p`
  margin: 0;
  font-size: 40px;
  font-weight: 900;
  color: #00365f;
  text-align: left;
  @media (max-width: 937px) {
    text-align: center;
  }
  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const Text = styled.p`
  font-size: 24px;
  font-weight: normal;
  color: #00365f;
  @media (max-width: 937px) {
    text-align: center;
  }
  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const ContainerSVG = styled.div`
  display: flex;
  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
  }
`;

export const IconSVG = styled.img`
  width: 458px;
  height: auto;
  object-fit: cover;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  margin-top: 20px;
  @media (max-width: 768px) {
    margin-bottom: 40px;
    margin-top: 0;
  }
`;
