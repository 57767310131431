import styled from "styled-components";

export const ContainerSection = styled.div`
  display: flex;
  margin: 30px 0 0 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  @media (max-width: 768px) {
    align-items: center;
    width: 80%;
  }
`;

export const Image = styled.img``;

export const Title = styled.h1`
  font-size: 28px;
  text-align: center;
  color: #00365f;
  font-weight: bold;
  @media (max-width: 1000px) {
    font-size: 20px;
    margin-bottom: 0px;
  }
  @media (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 0px;
  }
`;
