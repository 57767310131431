import styled from "styled-components";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 30px 0px 80px 0px;
  @media (max-width: 768px) {
    margin: 0px 0px 60px 0px;
  }
`;

export const ContainerSpaceAccordions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 20px;
  @media (max-width: 768px) {
    margin: 20px;
    gap: 0;
  }
`;

export const AccordionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  background-color: #f8f8f8;
  border-radius: 5px;
  @media (max-width: 768px) {
    width: 100%;
    margin: 10px;
  }
`;

export const ContainerSpace = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 15px;
  width: 90%;
  gap: 20px;
  margin-left: 20px;
  @media (max-width: 768px) {
    margin-left: 0px;
  }
`;

export const title = styled.h1`
  color: #00365f;
  font-weight: bold;
  font-size: 20px;
  @media (max-width: 768px) {
    font-size: 17px;
  }
`;

export const ContainerIcon = styled.div`
  display: flex;
  margin-right: 20px;
  align-items: center;
  justify-content: center;
  width: 100px;
  @media (max-width: 768px) {
    margin-right: 0px;
  }
`;

export const IconArrow = styled(FaAngleDown)`
  color: #9ac31c;
  transition: color 0.3s;
`;

export const IconArrowSecundario = styled(FaAngleUp)`
  color: #9ac31c;
  transition: color 0.3s;
`;

export const ContainerAlignAccordion = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: #9ac31c;

    ${IconArrow} {
      color: white;
    }
    ${IconArrowSecundario} {
      color: white;
    }
  }

  ${(props) =>
    props.active &&
    `
    background-color: #e0e0e0;
  `}
`;

export const AccordionTitle = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px;
  width: 90%;
  gap: 20px;
  color: #00365f;
  font-weight: bold;
  font-size: 20px;
  align-items: center;
`;

export const Image = styled.img`
  width: 40px;
  height: 40px;
  @media (max-width: 768px) {
  }
`;

export const AccordionContent = styled.p`
  padding: 20px;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: #00365f;
  ${(props) =>
    !props.active &&
    `
    display: none;
  `}
`;
