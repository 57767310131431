// ButtonFlutuante.js

import React, { useState, useEffect } from "react";
import { IoIosArrowUp } from "react-icons/io";
import * as S from "./styles";

export default function ButtonFlutuante() {
  const [isvisible, setisvisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    setisvisible(scrollTop > 300);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <S.StyledButton onClick={scrollToTop} isvisible={`${isvisible}`}>
      <S.ContainerIconAlign>
        <IoIosArrowUp color="#00365f" size={28} />
      </S.ContainerIconAlign>
    </S.StyledButton>
  );
}
