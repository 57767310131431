import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import { FaBars } from "react-icons/fa";
import logo from "../../imgs/logoVox/logo.svg";
import ButtonContact from "../ButtonContact";
import * as S from "./styles";

export default function NavBar({ isTabs }) {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const closeMenuIfMobile = () =>
    window.innerWidth <= 768 && setMenuOpen(false);

  const scrollToTop = () => {
    scroll.scrollToTop();
    closeMenuIfMobile();
  };

  const smoothScroll = (path) => {
    navigate(path);
    closeMenuIfMobile();
  };

  useEffect(() => {
    window.addEventListener("resize", closeMenuIfMobile);
    return () => {
      window.removeEventListener("resize", closeMenuIfMobile);
    };
  }, []);

  return (
    <S.Container>
      <S.Nav>
        <S.Ul>
          <S.Tag to="/" onClick={scrollToTop}>
            <S.Img src={logo} className="img" alt="logo" />
          </S.Tag>
          <S.DesktopMenu>
            <S.TagsSpacing className="tagsSpacing">
              <S.Tag to="/">Inicio</S.Tag>
              {isTabs && (
                <ScrollLink
                  to="chatbot_"
                  smooth={true}
                  duration={800}
                  onClick={() => smoothScroll("/chatbot_")}
                >
                  <S.Tag>Chatbot</S.Tag>
                </ScrollLink>
              )}
              {isTabs && (
                <ScrollLink
                  to="funcionalidades"
                  smooth={true}
                  duration={800}
                  onClick={() => smoothScroll("/funcionalidades")}
                >
                  <S.Tag>Funcionalidades</S.Tag>
                </ScrollLink>
              )}
              <ScrollLink
                to="contato"
                smooth={true}
                duration={800}
                onClick={() => smoothScroll("/contato")}
              >
                <S.Tag>Contato</S.Tag>
              </ScrollLink>

              <ButtonContact
                title="Fale com a equipe"
                url="https://wa.me/+554834782300"
              />
            </S.TagsSpacing>
          </S.DesktopMenu>

          <S.MobileMenu onClick={toggleMenu}>
            <FaBars size={32} color="#00365f" />
          </S.MobileMenu>
        </S.Ul>
        {menuOpen && (
          <S.MobileMenuContainer>
            <S.Tag to="/">Inicio</S.Tag>
            {isTabs && (
              <ScrollLink
                to="chatbot_"
                smooth={true}
                duration={800}
                onClick={() => {
                  smoothScroll("/chatbot_");
                }}
              >
                <S.Tag>Chatbot</S.Tag>
              </ScrollLink>
            )}

            {isTabs && (
              <ScrollLink
                to="funcionalidades"
                smooth={true}
                duration={800}
                onClick={() => {
                  smoothScroll("/funcionalidades");
                }}
              >
                <S.Tag>Funcionalidades</S.Tag>
              </ScrollLink>
            )}
            <ScrollLink
              to="contato"
              smooth={true}
              duration={800}
              onClick={() => {
                smoothScroll("/contato");
              }}
            >
              <S.Tag>Contato</S.Tag>
            </ScrollLink>
          </S.MobileMenuContainer>
        )}
      </S.Nav>
    </S.Container>
  );
}
