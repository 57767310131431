import React from "react";
import IntroducaoText from "../IntroducaoText";
import Accordion from "../Accordion";
import * as S from "./styles";

export default function IntroducaoChatbot() {
  return (
    <S.Container id="chatbot_">
      <IntroducaoText
        title="Esta com dúvida"
        subtitle="Porque contratar o Chatbot ?"
        subtitleColor="white"
      />
      <Accordion />
    </S.Container>
  );
}
