import React from "react";
import * as S from "./styles";

export default function IntroducaoText({
  title,
  subtitle,
  titleColor,
  titleFontWeight,
  fontSize,
  subtitleFontWeight,
  subtitleFontSize,
  subtitleColor,
  marginleft,
  space,
}) {
  return (
    <S.ContainerSectionTitles>
      <S.ContainerSeparator space={space} />
      <S.Title
        color={titleColor}
        fontWeight={titleFontWeight}
        fontSize={fontSize}
      >
        {title}
      </S.Title>
      <S.SubTitle
        fontWeight={subtitleFontWeight}
        fontSize={subtitleFontSize}
        color={subtitleColor}
        marginleft={marginleft}
      >
        {subtitle}
      </S.SubTitle>
    </S.ContainerSectionTitles>
  );
}
