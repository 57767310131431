import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home/index";
import ChatBot from "./pages/ChatBot";
import ChatInterno from "./pages/ChatInterno";
import Agendamento from "./pages/Agendamento";
import VideoChamada from "./pages/VideoChamada";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/chatbot_" element={<Home state={true} />} />
          <Route path="/funcionalidades" element={<Home state={true} />} />
          <Route path="/contato" element={<Home state={true} />} />
          <Route path="/chatbot" element={<ChatBot />} />
          <Route path="/chatInterno" element={<ChatInterno />} />
          <Route path="/agendamento" element={<Agendamento />} />
          <Route path="/videochamada" element={<VideoChamada />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
