import React from "react";
import { funcionalidades } from "../../../../data";
import IntroducaoText from "../../../../components/IntroducaoText";
import CardSolucoes from "../../../../components/CardSolucoes";
import * as S from "./styles";

export default function IntroducaoFuncionalidades() {
  return (
    <S.Container id="funcionalidades">
      <IntroducaoText
        title="Oferecemos as melhores soluções para você !"
        titleColor="#00365f"
        titleFontWeight="bold"
      />
      <S.ContainerAlign>
        {funcionalidades.map((item, index) => (
          <CardSolucoes
            key={index}
            card={item}
            index={index}
            showButton={true}
          />
        ))}
      </S.ContainerAlign>
    </S.Container>
  );
}
