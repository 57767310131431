import styled, { keyframes } from "styled-components";

export const ani = keyframes`
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px); 
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const ContainerIntroducao = styled.div`
  display: flex;
  width: 90%;
  justify-content: space-between;
  @media (max-width: 768px) {
    align-items: center;
    width: 100%;
    flex-direction: column;
  }
`;

export const ContainerColumn = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  margin-top: 56px;
  @media (max-width: 768px) {
    width: 95%;
  }
`;

export const ContainerColumnText = styled.div`
  display: flex;
  margin-left: 30px;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
    margin-left: 0px;
  }
`;

export const Title = styled.h1`
  color: #00365f;
  font-size: 32px;
  font-weight: bolder;
  margin-top: 0px;
  @media (max-width: 768px) {
    font-size: 30px;
    text-align: center;
  }
`;

export const Text = styled.h1`
  color: #00365f;
  font-size: 27px;
  font-weight: normal;
  margin-bottom: 50px;
  max-width: 95%;
  @media (max-width: 768px) {
    font-size: 22px;
    text-align: center;
    width: 100%;
  }
`;

export const ContainerImageMockup = styled.div`
  display: flex;
  margin: 0px 0px 30px 0px;
  @media (max-width: 768px) {
    width: 90%;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  }
`;

export const ImageMockup = styled.img`
  width: 100%;
  max-width: 700px;
  animation: ${ani} 2s ease-in-out infinite alternate-reverse both;
`;

export const ContainerButton = styled.div`
  display: flex;
  @media (max-width: 1180px) {
    margin-bottom: 50px;
  }
`;
