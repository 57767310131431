import React from "react";
import ButtonSaibaMais from "../ButtonSaibaMais";
import foguete from "../../imgs/backgrounds/foguete.svg";
import * as S from "./styles";

export default function BannerDesbloqueie() {
  return (
    <S.Container>
      <S.ContainerAlign>
        <S.Space>
          <S.ContainerSpaceTextos>
            <S.AlignTextos>
              <S.Title>Desbloqueie o extraordinário </S.Title>
              <S.subtitle>Vamos começar agora ?</S.subtitle>
            </S.AlignTextos>
            <S.Text>
              Potencialize seu caminho para o sucesso com benefícios exclusivos
              e inicie uma transformação em sua vida. Abra portas para um novo
              capitulo cheio de confiança e determinação. Não perca mais tempo,
              adquira agora e faça cada momento valer a pena!
            </S.Text>
            <S.ContainerButton>
              <ButtonSaibaMais
                backgroundcolorhover="#13385c"
                title="Adquira já !"
                colorhover="white"
                boxshadowhover="white"
                onClick={() =>
                  window.open("https://wa.me/+554834782300/", "_blank")
                }
              />
            </S.ContainerButton>
          </S.ContainerSpaceTextos>
          <S.ContainerSVG>
            <S.IconSVG src={foguete} alt=" foguete" />
          </S.ContainerSVG>
        </S.Space>
      </S.ContainerAlign>
    </S.Container>
  );
}
