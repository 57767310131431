import React from "react";
import Mockup from "../../imgs/logoVox/logoMockup.svg";
import ButtonSaibaMais from "../ButtonSaibaMais";
import * as S from "./styles";

export default function Section() {
  return (
    <S.Container>
      <S.ContainerIntroducao>
        <S.ContainerColumn>
          <S.ContainerColumnText>
            <S.Title>Comunicação inovadora e eficiente</S.Title>
            <S.Text>
              Transforme sua comunicação com nossa solução inovadora e
              eficiente, projetada para clareza e rapidez. Experimente e eleve
              suas interações a um novo patamar!
            </S.Text>
            <S.ContainerButton>
              <ButtonSaibaMais
                title="Saber mais"
                backgroundcolorhover="#9ac31c"
                onClick={() => window.open("/chatbot", "_blank")}
              />
            </S.ContainerButton>
          </S.ContainerColumnText>
        </S.ContainerColumn>
        <S.ContainerImageMockup>
          <S.ImageMockup src={Mockup} className="img" alt="logo" />
        </S.ContainerImageMockup>
      </S.ContainerIntroducao>
    </S.Container>
  );
}
