import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
  }
`;

export const Button = styled.button`
  display: flex;
  font-family: "Raleway", sans-serif;
  background-color: #00365f;
  color: white;
  border-radius: 5px;
  padding: 18px 36px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: ${(props) => props.backgroundcolorhover};
    color: ${(props) => props.colorhover};
    border: none;
    box-shadow: 0px 1px 10px 10px ${(props) => props.boxshadowhover};
    transition: all 0.3s ease;
  }
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
