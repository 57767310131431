import styled from "styled-components";

export const ContainerSectionTitles = styled.div`
  display: flex;
  margin-top: 80px;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const ContainerSeparator = styled.div`
  width: 69px;
  height: 8px;
  border-radius: 8px;
  background-color: #9ac31c;
  margin-left: ${(props) => props.space || "0px"};
  @media (max-width: 768px) {
    margin-left: 0px;
  }
`;

export const Title = styled.p`
  font-size: ${(props) => props.fontSize || "22px"};
  color: ${(props) => props.color || "#9AC31C"};
  font-weight: ${(props) => props.fontWeight || "normal"};
  margin-bottom: ${(props) => props.marginBottom || "0px"};
  @media (max-width: 768px) {
    font-size: 18px;
    margin-bottom: 0px;
    text-align: center;
  }
`;

export const SubTitle = styled.p`
  font-size: ${(props) => props.fontSize || "35px"};
  color: ${(props) => props.color || "#00365f"};
  font-weight: ${(props) => props.fontWeight || "bold"};
  margin-top: 20px;
  text-align: justify;
  flex-wrap: wrap;
  margin-left: ${(props) => props.marginleft || "0px"};
  @media (max-width: 768px) {
    font-size: 20px;
    margin-left: 0px;
    margin: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }
  @media (min-width: 1000px) and (max-width: 1300px) {
    margin-left: 0px;
  }
  @media (min-width: 1306px) and (max-width: 1498px) {
    margin-left: 0px;
  }

  @media (min-width: 1346px) and (max-width: 1500px) {
    margin-left: 0px;
  }

  @media (min-width: 1320px) and (max-width: 1500px) {
    margin-left: 0px;
  }

  @media (min-width: 1600px) and (max-width: 2000px) {
    margin-left: 0px;
  }

  @media (min-width: 1521px) and (max-width: 1438px) {
    margin-left: 0px;
  }

  @media (min-width: 773px) and (max-width: 988px) {
    margin-left: 0px;
  }
`;
