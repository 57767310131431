import React, { useEffect } from "react";
import * as S from "./styles";

export default function CardSolucoes({ card, index, showButton }) {
  const { image, title, subTitle, text } = card;

  const handleButtonClick = () => {
    window.open(card.route, "_blank");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <S.Container>
      <S.ContainerAlignCards key={index}>
        <S.ContainerImageBorder index={index}>
          <S.Image src={image} />
          {showButton && (
            <S.ContainerButton>
              <S.Button onClick={() => handleButtonClick()}>
                Saiba mais
              </S.Button>
            </S.ContainerButton>
          )}
        </S.ContainerImageBorder>
        <S.ContainerTexts>
          <S.Title>{title}</S.Title>
          <S.SubTitle>{subTitle}</S.SubTitle>
          <S.Text>{text}</S.Text>
        </S.ContainerTexts>
      </S.ContainerAlignCards>
    </S.Container>
  );
}
